import Debug from 'debug'
import React from 'react'
import { Link } from 'gatsby'
import TextRow from '../components/TextRow'
import ReactPlayer from 'react-player/lib/players/FilePlayer'
import Record from '../components/Record'
import SvgSkipNext from '../components/sharp-skip_next-24px.svg'
import SvgPause from '../components/sharp-pause-24px.svg'
import ExternalLink from '../components/ExternalLink'

const debug = Debug('iw:components:trackplayer')

class Player extends React.Component {
  constructor (props) {
    super(props)
    const { playing } = props
    this.state = { playing }
  }
  checkMarquee () {
    debug('DID MOUNT')
  }
  handleRecordClick () {
    !this.state.playing && this.handlePlay()
  }
  handlePause () {
    debug(`🎷 handlePause`)
    this.setState(state => ({ ...state, playing: false }))
  }
  handlePlay () {
    debug(`🎷 handlePlay`)
    this.setState(state => ({ ...state, playing: true }))
  }
  handleMarqueeMaybe (e) {
    // TODO http://jsfiddle.net/MaY5A/1/
    debug('scrollWidth', e.target.scrollWidth)
    debug('innerText', e.target.innerText)
    debug('et', e.target.offsetWidth)
  }

  render () {
    const {
      track: {
        artistName,
        fileName,
        recordDescription,
        trackName,
        url
      }
    } = this.props
    debug('props', this.props)
    const { playing } = this.state
    debug('state', this.state)

    return (
      <div className='h-100 f4 f3-ns f2-m f1-l'>
        <TextRow>
          <div className='nowrap'>
            <span>
              <Link
                to='/'
                state={{ playing }}
              >
                <SvgSkipNext cursor='pointer' height='1.5em' width='1.5em' />
              </Link>
            </span>
            {this.state.playing && (
              <span onClick={() => this.handlePause()}>
                <SvgPause cursor='pointer' height='1.5em' width='1.5em' />
              </span>
            )}
          </div>
          <div className='nowrap w-100 marquee ml3 pt1 pt2-ns'>
            <span className='marquee-inner'>{artistName}</span>
          </div>
        </TextRow>
        <div className='pa3'>
          <Record
            onClick={() => this.handleRecordClick()}
            artistName={this.state.playing && artistName}
            trackName={this.state.playing && trackName}
            playing={this.state.playing}
          />
        </div>
        <TextRow>
          <ExternalLink href={url}>{recordDescription}</ExternalLink>
        </TextRow>
        <ReactPlayer
          url={`/${fileName}`}
          onBuffer={() => debug('Buffer')}
          onEnded={() => {
            debug('Ended')
            this.handlePause()
          }}
          onError={console.error}
          onPause={() => debug('Pause')}
          onPlay={() => debug('Play')}
          onProgress={p => debug('Progress', p)}
          onReady={() => debug('Ready')}
          playing={this.state.playing}
          file={{ forceAudio: true }}
          controls={false}
          width='100%'
          height='0px' >
          <span aria-label='mad face' role='img'>{'😤 '}</span>
          Your browser does not support the <code>audio</code> element.
        </ReactPlayer>
      </div>
    )
  }
}

export default Player
