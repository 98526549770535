import React from 'react'
import Layout from '../components/layout'
import Player from '../components/trackplayer'

const Track = ({ pageContext, location }) => {
  const playing = location && location.state && location.state.playing
  return (
    <Layout className='h-100'>
      <Player {...pageContext} playing={playing} />
    </Layout>
  )
}

export default Track
